﻿/**
 * Jdenticon
 * https://github.com/dmester/jdenticon
 * Copyright © Daniel Mester Pirttijärvi
 */

export const SVG_CONSTANTS = {
    XMLNS: "http://www.w3.org/2000/svg",
    WIDTH: "width",
    HEIGHT: "height",
}